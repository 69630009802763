import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"
import theme from "../components/theme"
import {
  Paper,
  Typography,
  Card,
  makeStyles,
  Container
} from "@material-ui/core"

const useStyles = makeStyles({
  cardStyle: {
    textAlign: "justify",
    padding: "10px 0",
    '& ol': {
      paddingRight: "25px",
      margin: "7px 0"
    },
    '& ul': {
      paddingRight: "25px",
      margin: "7px 0"
    }
  },
  subTitle: {
    padding: "10px 0 0",
    color: theme.palette.secondary.main
  }
})

const PrivacyPolicyPage = () => {

  const classes = useStyles()

  return (
    <Layout>
      <SEO title="מדיניות פרטיות" />
      <Helmet />
      
      <Paper
        elevation={0}
        variant="outlined"
        style={{ padding: "50px 0" }}
      >
        <Container style={{ maxWidth: "1024px", }}>
          <Typography variant="h3" component="h2" style={{color: theme.palette.primary.main}}>

            מדיניות פרטיות

          </Typography>
          <Typography component="p">
            ברוכים הבאים לאפליקציית קיצור דרך להלן "אפליקציה"
          </Typography>

          <Card className={classes.cardStyle} elevation={0}>
            <Typography className={classes.subTitle} component="h3">
              הקדמה
            </Typography>
            <ol>
              <li>
                הפרטיות של משתמשי האפליקציה שלנו חשובה לנו מאוד, ואנחנו מחויבים לשמירה עליה. המדיניות הזו מסבירה מה נעשה עם המידע שלכם.
              </li>
              <li>
                אפליקציית ״קיצור דרך״ לא אוספת מידע אישי מהמשתמשים
              </li>
              <li>
                אנכנו אוספים מידע אנונימי על השימוש באפליקציה שנועד לצורך שיפור האפליקציה
              </li>
              <li>
                המידע שנאסף לא נמכר לצד שלילי
              </li>
            </ol>
            <Typography className={classes.subTitle} component="h3">
              איסוף מידע
            </Typography>
            <Typography variant="body2" component="p">
              ייתכן שהסוגים הבאים של מידע ייאספו, יאוחסנו, ויעשה בהם שימוש:
            </Typography>
            <ul>
              <li>
                מידע על השימוש שלכם באפליקציה, כולל מקור ההפניה, אורך השימוש, צפיות בעמודים, ונתיבי המעבר שלכם באפליקציה;
              </li>
              <li>
                מידע על השימוש שלכם באפליקציה, כולל מקור ההפניה, אורך השימוש, צפיות בעמודים, ונתיבי המעבר שלכם באפליקציה;
              </li>
              <li>
                המידע שאתם מזינים בזמן השימוש באפליקציה כגון תשובות לשאלות במבחן וכו׳;
              </li>
              <li>
                מידע שנכלל במסרים שאתם שולחים לנו בדואר האלקטרוני או באמצעות מסרונים, כולל תוכן המסר והמטא-דאתה שלו;
              </li>
              <li>
                כל סוג אחר של פרטים אישיים שאתם שולחים אלינו.
              </li>
              <Typography variant="body2" component="p">
                לפני שתחשפו בפנינו את הפרטים האישיים של אדם אחר, עליכם לבקש את הסכמת האדם הזה גם עבור השיתוף וגם עבור העיבוד של אותם הפרטים האישיים לפי מדיניות זו.
                המידע האנונימי עובר איבוד על ידי הספקים הבאים:
              </Typography>
              <ol>
                <li>Google.com</li>
                <li>Amplitude.com</li>
                <li>Sentry.com</li>
                <li>Facebook.com</li>
              </ol>
            </ul>
          
            <Typography className={classes.subTitle} component="h3">
              שימוש בפרטים האישיים שלכם
            </Typography>
            <Typography variant="body2" component="p">
              בפרטים אישיים שנמסרו לנו ייעשה שימוש למטרות שצוינו במדיניות זו. ייתכן שנשתמש בפרטים האישיים שלכם למטרות הבאות:
            </Typography>
            <ol>
              <li>לשלוח לכם התראות בדואר אלקטרוני שביקשתם באופן ספציפי;</li>
              <li>שליחה של הניוזלטר שלנו אליכם בדואר אלקטרוני, אם ביקשתם זאת (אתם יכולים להודיע לנו בכל עת שכבר אין לכם צורך בניוזלטר);</li>
              <li>שליחה של מסרים שיווקיים שקשורים לעסק שלנו והעסקים של חברות צד ג’ שנבחרו בקפידה שלדעתנו עשויות לעניין אתכם, בדואר או, במקרים שבהם הסכמתם לכך ספציפית, בדואר האלקטרוני, או באמצעות טכנולוגיות דומות (אתם יכולים ליידע אותנו בכל עת אם אתם כבר לא מעוניינים במסרים שיווקיים);</li>
              <li>אספקה של מידע סטטיסטי בנוגע למשתמשים שלנו לצד ג’ (אבל צד ג’ זה לא יוכל לזהות אף משתמש בודד לפי המידע);</li>
              <li>טיפול בבירורים ותלונות שהוגשו על ידכם או נוגעות אליכם וקשורות לאפליקציה שלנו;</li>
              <li>לשמור על האפליקציה שלנו מאובטחת ולמנוע הונאה;</li>
              <li>אימות של היענות לתנאי השירות המכתיבים את אופן השימוש באפליקציה (כולל ניטור של הודעות פרטיות שנשלחו דרך שירות ההודעות הפרטיות);</li>
            </ol>
            <Typography variant="body2" component="p">
              אנחנו לא נספק את הפרטים האישיים שלכם ללא הסכמתכם לכל צד ג’ שהוא, לצורך השיווק הישיר שלו, או של כל צד ג’ אחר.
            </Typography>
          
            <Typography className={classes.subTitle} component="h3">
              חשיפת פרטים אישיים
            </Typography>
            <Typography variant="body2" component="p">
              אנחנו עשויים למסור את הפרטים האישיים שלכם לכל אחד מהעובדים, המנהלים, המבטחים, היועצים המקצועיים, הסוכנים, הספקים, או קבלני המשנה שלנו, במידה סבירה וכנדרש למטרות המצוינות במדיניות זו.
              אנחנו עשויים למסור את הפרטים האישיים שלכם לכל חברה בקבוצת החברות שלנו (זה אומר כל חברות הבת שלנו, חברת הגג שלנו, וכל חברות הבת שלה) במידה סבירה וכנדרש למטרות המצוינות במדיניות זו.
            </Typography>
            <Typography variant="body2" component="p">
              אנחנו עשויים למסור את הפרטים האישיים שלכם:
            </Typography>
            <ol>
              <li>לפי מה שנדרש מאתנו על פי חוק;</li>
              <li>בהקשר של כל הליך משפטי קיים או עתידי;</li>
              <li>על מנת לבסס, להפעיל, או להגן על זכויותינו המשפטיות (כולל אספקה של מידע לאחרים למטרות מניעת הונאה והפחתה של סיכון אשראי);</li>
              <li>לרוכש (או הרוכש הפוטנציאלי) של העסק שלנו.</li>
              <li>ולכל אדם שאנחנו מאמינים במידה סבירה שעשוי לפנות לבית דין או לכל רשות מוסמכת לצורך מסירה של הפרטים האישיים בהם סביר לדעתנו שאותו בית דין או רשות יורה על מסירה של פרטים אישיים אלה.</li>
            </ol>
            <Typography variant="body2" component="p">
              למעט על פי הכתוב במדיניות זו, אנו לא נספק את הפרטים האישיים שלכם לצד ג’.
            </Typography>
          
            <Typography className={classes.subTitle} component="h3">
              העברות נתונים בינלאומיות
            </Typography>
            <ol>
              <li>ייתכן שמידע שאנחנו אוספים יאוחסן, יעובד, ויועבר בין כל אחת מהמדינות בהן אנו פועלים, על מנת לאפשר לנו להשתמש במידע בהתאם למדיניות זו.</li>
              <li>ייתכן שמידע שאנחנו אוספים יועבר למדינות הבאות:
                <ul>
                  <li>מדינות האזור הכלכלי האירופי.</li>
                  <li>ארצות הברית של אמריקה.</li>
                </ul>
              </li>
            </ol>
          
            <Typography className={classes.subTitle} component="h3">
              שמירת פרטים אישיים
            </Typography>
            <ol>
              <li>סעיף זה מפרט את תהליך ומדיניות שמירת הנתונים שלנו, המתוכננים לעזור להבטיח שאנחנו נענים לחובות המשפטיות שלנו הנוגעות לשמירה ולמחיקה של פרטים אישיים.</li>
              <li>פרטים אישיים שאנחנו מעבדים עבור כל מטרה או מטרות, לא יישמרו יותר מכמה שנדרש עבור מטרה או מטרות אלה.</li>
              <li>אנו נשמור מסמכים (כולל מסמכים אלקטרוניים) המכילים נתונים אישיים:
                <ul>
                  <li>לפי מה שנדרש מאתנו על פי חוק;</li>
                  <li>אם לדעתנו המסמכים עשויים להיות רלוונטיים לכל הליך משפטי מתמשך או פוטנציאלי;</li>
                  <li>על מנת לבסס, להפעיל, או להגן על זכויותינו המשפטיות (כולל אספקה של מידע לאחרים למטרות מניעת הונאה והפחתה של סיכון אשראי).</li>
                </ul>
              </li>
            </ol>
          
            <Typography className={classes.subTitle} component="h3">
              אבטחת הפרטים האישיים שלכם
            </Typography>
            <ol>
              <li>אנחנו ננקוט משנה זהירות ארגוני וטכני סביר על מנת למנוע את האבדן, השימוש לרעה, או השינוי של הפרטים האישיים שלכם.</li>
              <li>אנחנו נאחסן את כל הפרטים האישיים שאתם מספקים בשרתים מאובטחים.</li>
            </ol>
          
            <Typography className={classes.subTitle} component="h3">
              תיקונים
            </Typography>
            <Typography variant="body2" component="p">
              אנחנו עשויים לעדכן מדיניות זו מעת לעת באמצעות פרסום של גרסה חדשה באתר שלנו. עליכם לבדוק את העמוד הזה מדי פעם על מנת לוודא שאתם מבינים כל שינוי שנעשה במדיניות זו. אנחנו עשויים להודיע לכם על שינויים במדיניות זו בהודעת דואר אלקטרוני או דרך מערכת ההודעות הפרטיות שלנו.
            </Typography>
          
            <Typography className={classes.subTitle} component="h3">
              הזכויות שלכם
            </Typography>
            <Typography variant="body2" component="p">
              אתם יכולים להורות לנו לספק לכם כל פרט מפרטיכם האישיים שאנו מחזיקים הנוגעים אליכם; אספקת פרטים אלה תהיה כפופה לתנאים הבאים:
            </Typography>
            <ol>
              <li>תשלום של עמלה בגובה של 25 ש״ח;</li>
              <li>אספקה של ראיות הולמות עבור הזהות שלכם. לרוב נקבל צילום של הדרכון או תעודת הזהות שלכם.</li>
            </ol>
            <Typography variant="body2" component="p">
              אנחנו עשויים לסרב למסור פרטים אישיים שאתם מבקשים עד למידה המורשית על פי חוק.
              אתם יכולים להורות לנו בכל עת לא לעבד את הפרטים האישיים שלכם לצורכי שיווק.
            </Typography>
          
            <Typography className={classes.subTitle} component="h3">
              אתרים צד ג’
            </Typography>
            <Typography variant="body2" component="p">
              האפליקציה שלנו כולל קישורים חיצוניים, ופרטים על, אתרים צד ג’. אין לנו שליטה על, ואיננו אחראים על, מדיניות הפרטיות והנהלים של כל צד ג’.
            </Typography>
          
            <Typography className={classes.subTitle} component="h3">
              עדכון מידע
            </Typography>
            <Typography variant="body2" component="p">
              אנא הודיעו לנו אם יש צורך לתקן או לעדכן את הפרטים האישיים שאנו מחזיקים עליכם.
            </Typography>
          </Card>
        </Container>
      </Paper>
      
    </Layout>
  )
}

export default PrivacyPolicyPage
